import React from 'react';
import { graphql, navigate } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Card from '@mui/material/Card';

// custom components
import { useSettings } from '../../hooks/SettingsHook';
import { Layout } from '../../components/layout/PageSingle';
import StyledBox from '../../components/controls/StyledBox';
import Booking from '../../custom/segments/Booking';
import Gallery from '../../custom/segments/Gallery';
import Newsletter from '../../custom/segments/NewsletterSimple';
import * as pathUtils from '../../utils/path';
import * as nodesUtils from '../../utils/nodes';
import * as config from '../../../config/site';

export const query = graphql`
  query ($id: String) {
    page: datoCmsPage(id: { eq: $id }) {
      pagetype
      title
      coverHeader
      teaser
      content
      footer
      coverImage {
        gatsbyImageData(width: 1600)
        alt
      }
      gallery {
        alt
        basename
        fixed(width: 250) {
          src
        }
        gatsbyImageData(layout: CONSTRAINED, width: 1200, aspectRatio: 1.3)
      }
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    assortments: allDatoCmsAssortment(sort: { fields: position, order: ASC }) {
      edges {
        node {
          id
          title
          originalId
          catalogs {
            id
            title
            originalId
          }
        }
      }
    }
    catalogs: allDatoCmsCatalog(sort: { fields: position, order: ASC }) {
      edges {
        node {
          id
          title
          originalId
        }
      }
    }
    siteSetting: datoCmsSiteSetting {
      title
      company
      catalog {
        id
        title
        originalId
        image {
          gatsbyImageData(width: 450)
          alt
          basename
        }
      }
      catalogAlt {
        id
        title
        originalId
        image {
          gatsbyImageData(width: 450)
          alt
          basename
        }
      }
      logo {
        gatsbyImageData(width: 120)
        alt
      }
    }
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
  }
`;

export default function BookingPage({ data, pageContext }) {
  // context
  const { settings } = useSettings() || {};

  // parameter management
  const { page, assortments, catalogs, siteSetting, site } = data;
  const { catalogType, catalog } = pageContext;
  const allAssortments = assortments.edges;
  const allCatalogs = catalogs.edges;

  // state

  // data management
  const pageHeader = nodesUtils.getLabelFromPageType(page.pagetype);
  const coverHeader = page && page.coverHeader ? page.coverHeader : page.title;

  const showNewsletter = config.segments.newsletter.enabled;

  const galleryImages = nodesUtils.getGalleryImages(page.gallery);
  const pageParams = {
    title: coverHeader,
    cover: { ...page.coverImage },
  };

  const productParams = settings && settings.product ? { ...settings.product } : {};
  const userParams = {
    firstName: '',
    lastName: '',
    code: '',
    department: '',
  };

  // event handler
  const handleBackClick = () => {
    // Version 1: Go back to caller product
    // const navlink = settings && settings.caller ? settings.caller.booking : "";

    // Version 2: Go back to landing page of actual catalog
    const catalogSettings = nodesUtils.getSiteCatalogs(siteSetting, allCatalogs);
    const navlink = pathUtils.createInitialAssortmentPagePathOfCatalog(
      catalogType,
      allAssortments,
      catalogSettings
    );

    navigate(navlink);
  };

  return (
    <Layout catalogType={catalogType} catalogId={catalog} pageParams={pageParams}>
      <HelmetDatoCms seo={page.seo} favicon={site.favicon} />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 7 },
          mt: -8,
          mb: 1,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 1),
          backdropFilter: 'saturate(200%) blur(30px)',
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <StyledBox py={{ xs: 1, md: 2 }}>
          <Booking
            header={pageHeader}
            title={page.title}
            teaser={page.teaser}
            body={page.content}
            footer={page.footer}
            user={userParams}
            product={productParams}
            onBackClick={handleBackClick}
          />
        </StyledBox>
        <Gallery header={'Bildergalerie'} images={galleryImages} />
        {showNewsletter && <Newsletter />}
      </Card>
    </Layout>
  );
}
